<template>
  <div class="flex flex-center align-center toast-bottom">
    <span></span>
    已经到底了
    <span></span>
  </div>
</template>
<style lang="scss" scoped>
.toast-bottom{
  width: 100%;
  height: auto;
  padding: 15px 0;
  color: #CCCCCC;
  font-size: 12px;
  font-weight: 400;
  span{
    width: 20px;
    height:1px;
    background-color: #CCCCCC;
  }
  span:nth-child(1){
    margin-right: 5px;
  }
  span:nth-child(2){
    margin-left: 5px;
  }
}
</style>