import {
    // getTransOrderDetail,
    getCashList,
    myProfitList
  } from '@/utils/user.js';
import toastBottom from '@/components/bottom.vue';
export default {
    name: "CashRecord",
    components: { toastBottom },
    data() {
        return {
            active: 2,
            list: [],
            finished: false,
            loading: false,
            pageNum: 1,
            oldPage: 0,
        }
    },
    created() {
      // this.active = Number(this.$route.query.active);
      // console.log('active', this.active)
      this.myProfitList();
      // if (this.active == 0) {
      //   this.myProfitList()
      // } else if (this.active == 2) {
      //   this.cashList();
      // }
    },
    mounted() {
    
    },
    methods: {
      // 点击切换tab标签栏
      clickTabFunc(e) {
        console.log('e', e)
        this.active = e;
        this.loading = false;
        this.finished = false;
        this.oldPage = 0;
        this.pageNum = 1;
        this.list = [];
        console.log('this.active', this.active)
        if (this.active == 0) {
          this.myProfitList()
        } else if (this.active == 2) {
          this.cashList();
        }
      },
        // cashDetail(id) {
        //     let data = {
        //         memberId: this.$cookies.get('memberId'),
        //         orderId: id,
        //         transactionType: 4,
        //       };
        //       getTransOrderDetail(data).then(res => {
        //         if (res.code == "0") {
        //           // this.userInfo = res.data || {}
        //         } else {
        //           this.$toast.fail(res.message)
        //         }
        //       })
        // },
        // 提现
        cashList() {
          if (this.oldPage == this.pageNum) return;
          this.oldPage = this.pageNum;
          let data = {
            memberId: this.$cookies.get('memberId'),
            type: 4,
            pageNo: this.pageNum,
            pageSize: 10
          };
          getCashList(data).then(res => {
            if (res && res.code == "0") {
              let aList = res.data;
              this.list = this.list.concat(aList);
              this.loading = false;
              if (aList.length < 10) {
                this.finished = true;
              } else {
                this.pageNum++;
              }
            }
          })
        },
        // 奖励
        myProfitList() {
          if (this.oldPage == this.pageNum) return;
          this.oldPage = this.pageNum;
          let data = {
            memberId: this.$cookies.get('memberId'),
            pageNo: this.pageNum,
            pageSize: 10
          }
          myProfitList(data).then(res => {
            console.log('奖励', res)
            if (res && res.code == 0) {
              let aList = res.data.list;
              this.list = this.list.concat(aList);
              this.loading = false;
              if (this.list.length >= res.data.total) {
                this.finished = true;
              } else {
                this.pageNum++;
              }
            }
          })
        }
    }
}