<template>
  <div class="cash-Record">
    <div class="tab-header">
      <van-tabs v-model="active" @click="clickTabFunc">
        <van-tab title="奖励收益" :key="0"></van-tab>
        <van-tab title="积分" :key="1"></van-tab>
        <van-tab title="提现记录" :key="2"></van-tab>
      </van-tabs>
    </div>
    <div v-if="active === 0" class="tab-content">
      <!-- <p class="title">最近</p> -->
      <div v-if="list.length > 0" class="c-box">
        <van-list
        v-model:loading="loading"
        :finished="finished"
        :immediate-check="false"
        @load="myProfitList"
        >
          <div v-for="(item, index) in list" :key="index" class="c-li flex">
            <div class="left">
              <p>{{ item.changeReason }}</p>
              <p>来自微信：{{ item.wxName }}</p>
            </div>
            <div class="right">
              <p v-if="item.canAmount || item.canAmount === 0">{{ Number(item.canAmount || 0) > 0 ? ('+' + parseFloat(item.canAmount / 100).toFixed(2)) : ('-' + parseFloat(item.canAmount / 100).toFixed(2)) }}</p>
              <p> {{item.gmtCreate}} </p>
            </div>
          </div>
          <!-- 已经到底了 -->
          <toast-bottom v-if="finished"></toast-bottom>
        </van-list>
      </div>
      <div v-else class="c-box">
        <div class="no-list">
          <img src="@/assets/img/user/cashRecord/no-data.png" alt="" />
          <p class="no-text">暂无明细</p>
        </div>
      </div>
    </div>
    <div v-if="active === 1" class="tab-content">
      <!-- <p class="title">最近</p> -->
      <div v-if="list.length > 0" class="c-box">
        <van-list
        v-model:loading="loading"
        :finished="finished"
        :immediate-check="false"
        @load="myProfitList"
        >
          <div v-for="(item, index) in list" :key="index" class="c-li flex">
            <div class="left">
              <p>{{ item.settleContent }}</p>
              <p>{{ item.gmtCreate }}</p>
            </div>
            <div class="right">
              <p v-if="item.applyAmount || item.applyAmount === 0">{{ Number(item.applyAmount || 0) > 0 ? ('+' + parseFloat(item.applyAmount / 100).toFixed(2)) : ('-' + parseFloat(item.applyAmount / 100).toFixed(2)) }}</p>
              <p v-show="item.afterApplyAmount">剩余可提现 ¥{{ parseFloat(item.afterApplyAmount / 100).toFixed(2) }}</p>
            </div>
          </div>
          <!-- 已经到底了 -->
          <toast-bottom v-if="finished"></toast-bottom>
        </van-list>
      </div>
      <div v-else class="c-box">
        <div class="no-list">
          <img src="@/assets/img/user/cashRecord/no-data.png" alt="" />
          <p class="no-text">暂无明细</p>
        </div>
      </div>
    </div>
    <div v-if="active === 2" class="tab-content">
      <!-- <p class="title">最近</p> -->
      <div v-if="list.length > 0" class="c-box">
        <van-list
        v-model:loading="loading"
        :finished="finished"
        :immediate-check="false"
        @load="cashList"
        >
          <div v-for="(item, index) in list" :key="index" class="c-li flex">
            <div class="left">
              <p>{{ item.settleContent }}</p>
              <p>{{ item.gmtCreate }}</p>
            </div>
            <div class="right">
              <p v-if="item.applyAmount || item.applyAmount === 0">{{ Number(item.applyAmount || 0) > 0 ? ('+' + parseFloat(item.applyAmount / 100).toFixed(2)) : ('-' + parseFloat(item.applyAmount / 100).toFixed(2)) }}</p>
              <p v-show="item.afterApplyAmount">剩余可提现 ¥{{ parseFloat(item.afterApplyAmount / 100).toFixed(2) }}</p>
            </div>
          </div>
          <!-- 已经到底了 -->
          <toast-bottom v-if="finished"></toast-bottom>
        </van-list>
      </div>
      <div v-else class="c-box">
        <div class="no-list">
          <img src="@/assets/img/user/cashRecord/no-data.png" alt="" />
          <p class="no-text">暂无明细</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="./index.js"></script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
<style lang="scss">
.cash-Record {
  .tab-header {
    height: 52px;
    background: #ffffff;
    border-radius: 0px 0px 10px 10px;
    // .van-tabs--line {
    //   width: 345px;
    //   margin: 0 auto;
    // }
    // .van-tabs__wrap {
    //   height: auto !important;
    // }
    // .van-tabs__nav {
    //   padding-top: 16px !important;
    //   padding-bottom: 15px !important;
    //   justify-content: space-between;
    // }
    // .van-tabs__line {
    //   width: 28px !important;
    //   height: 2px !important;
    //   background: #e62129 !important;
    //   bottom: 0 !important;
    // }
    // .van-tab {
    //   height: 21px !important;
    //   font-size: 15px !important;
    //   font-family: PingFangSC-Regular, PingFang SC;
    //   font-weight: 500;
    //   color: #999999 !important;
    //   line-height: 21px !important;
    //   padding: 0 !important;
    //   flex: unset !important;
    //   width: auto !important;
    // }
    // .van-tab:nth-child(1) {
    //   margin: 0 0 0 33px !important;
    // }
    // .van-tab:nth-child(2) {
    //   width: 45px !important;
    //   justify-content: flex-end !important;
    // }
    // .van-tab:nth-child(3) {
    //   margin: 0 17px 0 0 !important;
    // }
    // .van-tab--active {
    //   color: #333333 !important;
    // }
  }
}
</style>